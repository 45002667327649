import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import routes from "./pathes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(Meta);

const defaultTitle = "Investimentos POC - Znap Technologies";
router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${to.meta.title} - ${defaultTitle}`;
      return;
    }

    let getMenu = store.getters["auth/getMenu"] || [];
    if (getMenu.length) {
      for (const menu of getMenu) {
        let submenu = menu.menus.find((m) => m.frontend_route_path === to.path);
        if (submenu) {
          const { label } = submenu;
          document.title = `${label} - ${defaultTitle}`;
          break;
        } else {
          document.title = defaultTitle;
        }
      }
    } else {
      document.title = defaultTitle;
    }
  });
});

export default router;
